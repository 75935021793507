.mat-datepicker-content {
  box-shadow: 0 2px 4px rgba(188, 188, 189, 0.4) !important;
}

.mat-calendar-previous-button,
.mat-calendar-next-button {
  background: none !important;
  width: 1rem !important;
  height: 1rem !important;
  margin: 0 !important;
  padding: 0 !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  top: 1rem;
  &:after {
    display: none !important;
  }

  .mat-paginator-icon {
    display: none !important;
  }
  .mat-button-focus-overlay {
    display: none !important;
  }
  .mat-button-ripple {
    display: none !important;
  }
  .mat-button-wrapper {
    width: 1rem;
    height: 1rem;
    display: block;
    align-items: center;
    justify-content: center;
    mask-repeat: no-repeat;
    mask-position: center center;
    mask-size: contain;
    background: rgb(var(--color-california));
    cursor: pointer;
    transition: all 0.3s ease;
    &:hover {
      background: rgb(var(--color-primary));
    }
  }
  &.mat-button-disabled {
    .mat-button-wrapper {
      cursor: default;
      background: rgb(var(--color-florida));
      &:hover {
        background: rgb(var(--color-florida));
      }
    }
  }
}

.mat-calendar-next-button {
  margin: 0 1rem !important;
  .mat-button-wrapper {
    mask-image: url('../../assets/img/custom-icons/angle-right.svg');
  }
}
.mat-calendar-previous-button {
  margin: 0 1rem !important;
  .mat-button-wrapper {
    mask-image: url('../../assets/img/custom-icons/angle-left.svg');
  }
}

.mat-calendar-arrow {
  display: none;
}

.mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover
  > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.cdk-keyboard-focused
  .mat-calendar-body-active
  > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.cdk-program-focused
  .mat-calendar-body-active
  > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  color: rgb(var(--color-natasha));
}

.mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: none !important;
}

.mat-calendar-body-in-preview {
  color: rgb(var(--color-colorado)) !important;
}

.mat-calendar-body-in-range {
  .mat-calendar-body-cell-content {
    color: rgb(var(--color-natasha)) !important;
  }
}

.mat-calendar-abbr {
  font-family: Noto Sans, sans-serif;
  font-size: 12px;
  display: block;
  margin-top: 10px;
  font-style: normal;
  font-weight: 500;
  text-decoration: none !important;
  line-height: 14px;
  letter-spacing: -0.2399999946px;
  text-align: center;
  color: rgb(var(--color-texas));
}

.mat-datepicker-content .mat-calendar {
  height: auto !important;
}
//create/update vm page fix, can only select first day of month
.only-firstDay-fix {
  .mat-calendar-body {
    .mat-calendar-body-cell-container {
      pointer-events: none;
      border: 1px solid #d2d2d25c;
      opacity: 0.7;
      .mat-calendar-body-cell-content {
        color: grey;
      }
      .td[role='gridcell']:first-child {
        display: none;
      }
    }
    tr:first-child td:nth-child(2), tr[aria-hidden='true'] ~ tr:nth-child(2) td.mat-calendar-body-cell-container:first-child, .mat-calendar-body-label + td[role='gridcell'] {
      pointer-events: all;
      border: 1px solid #00000096;
      opacity: 1;
      .mat-calendar-body-cell-content {
        font-weight: 600;
        color: #000;
      }
    }
  }
}
