.mat-paginator {
  background: transparent !important;
}

.mat-paginator-navigation-first,
.mat-paginator-navigation-previous,
.mat-paginator-navigation-next,
.mat-paginator-navigation-last {
  background: none !important;
  width: 1rem !important;
  height: 1rem !important;
  margin: 0 !important;
  padding: 0 !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  .mat-paginator-icon {
    display: none !important;
  }
  .mat-button-focus-overlay {
    display: none !important;
  }
  .mat-button-ripple {
    display: none !important;
  }
  .mat-button-wrapper {
    width: 1rem;
    height: 1rem;
    display: block;
    align-items: center;
    justify-content: center;
    mask-repeat: no-repeat;
    mask-position: center center;
    mask-size: contain;
    background: rgb(var(--color-betty));
    cursor: pointer;
    transition: all 0.3s ease;
    &:hover {
      background: rgb(var(--color-primary));
    }
  }
  &.mat-button-disabled {
    .mat-button-wrapper {
      cursor: default;
      background: rgb(var(--color-florida));
      &:hover {
        background: rgb(var(--color-florida));
      }
    }
  }
}
.mat-paginator-navigation-first {
  .mat-button-wrapper {
    mask-image: url('../../assets/img/custom-icons/angle-double-left.svg');
  }
}
.mat-paginator-navigation-next {
  margin: 0 1rem !important;
  .mat-button-wrapper {
    mask-image: url('../../assets/img/custom-icons/angle-right.svg');
  }
}
.mat-paginator-navigation-previous {
  margin: 0 1rem !important;
  .mat-button-wrapper {
    mask-image: url('../../assets/img/custom-icons/angle-left.svg');
  }
}
.mat-paginator-navigation-last {
  .mat-button-wrapper {
    mask-image: url('../../assets/img/custom-icons/angle-double-right.svg');
  }
}
