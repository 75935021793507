.mat-header-cell {
  color: #000 !important;
}

.mat-cell,
.mat-footer-cell {
  font-size: 12px !important;
}
.cdk-program-focused {
  .mat-sort-header-container {
    border-bottom: none !important;
  }
}
