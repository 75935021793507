@use '../../../../node_modules/@angular/material/index' as mat;
@use '../../var' as var;
@use '../../material-vars' as mat_var;
@use '../mixins' as mixins;

// @formatter:off
:root {
  // Generic
  --padding-gutter: 1rem;
  --padding: 1.5rem;
  --padding-16: 1rem;
  --padding-12: 0.75rem;
  --padding-8: 0.5rem;
  --padding-4: 0.25rem;

  --color-accent: 255, 64, 129;
  --color-accent-contrast: 255, 255, 255;

  --color-warn: 244, 67, 54;
  --color-warn-contrast: 255, 255, 255;
  --base-bg: 255, 255, 255;
  --base-fg: 0, 0, 0;

  // hohoro theme colors
  --color-primary: 88, 86, 214;
  --color-primary-contrast: 255, 255, 255;

  --color-white: 255, 255, 255;
  --color-black: 28, 28, 30;

  --color-green-2: 199, 199, 204;
  --color-green-3: 174, 174, 178;
  --color-green-4: 142, 142, 147;

  --color-gren: 242, 242, 247;
  --color-gren-1: 229, 229, 234;
  --color-gren-2: 209, 209, 214;
  --color-gren-3: 199, 199, 204;
  --color-gren-4: 174, 174, 178;

  --color-selected: 234, 234, 239;

  --color-error: 255, 59, 48;
  --color-attention: 255, 149, 0;
  --color-accept: 52, 199, 89;
  --color-system: 88, 86, 214;
  --color-orange: 255, 204, 0;
  --color-links: 0, 122, 255;
  --color-teal: 90, 200, 250;
  --color-purple: 175, 82, 222;
  --color-pink: 255, 45, 85;

  --color-margaret: 88, 86, 214;
  --color-betty: 28, 28, 30;
  --color-natasha: 255, 255, 255;
  --color-tiffany: 52, 199, 89;
  --color-barbara: 255, 59, 48;
  --color-suzanne: 255, 149, 0;
  --color-lily: 255, 204, 0;
  --color-stephanie: 0, 122, 255;
  --color-lisa: 0, 0, 0;
  --color-california: 107, 107, 115;
  --color-denver: 108, 108, 108;
  --color-texas: 146, 146, 151;
  --color-florida: 174, 174, 178;
  --color-colorado: 209, 209, 214;
  --color-seattle: 234, 234, 239;
  --color-arizona: 241, 241, 245;
  --color-shpongle: 242, 242, 247;
  --color-band: 252, 252, 252;
  --color-blur: 255, 255, 255;
  --color-mila: 41, 170, 74;

  @screen sm {
    --padding-gutter: 1.875rem;
  }
  // Typography
  --font: Noto Sans, sans-serif;
  --font-weight-medium: 500;
  --font-caption: #{mat.font-weight(var.$config, caption) #{mat.font-size(var.$config, caption)}/#{mat.line-height(
      var.$config,
      caption
    )} mat.font-family(var.$config, caption)};
  --font-body-1: #{mat.font-weight(var.$config, body-1) #{mat.font-size(var.$config, body-1)}/#{mat.line-height(
      var.$config,
      body-1
    )} mat.font-family(var.$config, body-1)};
  --font-body-2: #{mat.font-weight(var.$config, body-2) #{mat.font-size(var.$config, body-2)}/#{mat.line-height(
      var.$config,
      body-2
    )} mat.font-family(var.$config, body-2)};
  --font-subheading-1: #{mat.font-weight(var.$config, subheading-1) #{mat.font-size(var.$config, subheading-1)}/#{mat.line-height(
      var.$config,
      subheading-1
    )} mat.font-family(var.$config, subheading-1)};
  --font-subheading-2: #{mat.font-weight(var.$config, subheading-2) #{mat.font-size(var.$config, subheading-2)}/#{mat.line-height(
      var.$config,
      subheading-2
    )} mat.font-family(var.$config, subheading-2)};
  --font-headline: #{mat.font-weight(var.$config, headline) #{mat.font-size(var.$config, headline)}/#{mat.line-height(
      var.$config,
      headline
    )} mat.font-family(var.$config, headline)};
  --font-title: #{mat.font-weight(var.$config, title) #{mat.font-size(var.$config, title)}/#{mat.line-height(
      var.$config,
      title
    )} mat.font-family(var.$config, title)};
  --font-display-1: #{mat.font-weight(var.$config, display-1) #{mat.font-size(var.$config, display-1)}/#{mat.line-height(
      var.$config,
      display-1
    )} mat.font-family(var.$config, display-1)};
  --font-display-2: #{mat.font-weight(var.$config, display-2) #{mat.font-size(var.$config, display-2)}/#{mat.line-height(
      var.$config,
      display-2
    )} mat.font-family(var.$config, display-2)};
  --font-display-3: #{mat.font-weight(var.$config, display-3) #{mat.font-size(var.$config, display-3)}/#{mat.line-height(
      var.$config,
      display-3
    )} mat.font-family(var.$config, display-3)};
  --font-display-4: #{mat.font-weight(var.$config, display-4) #{mat.font-size(var.$config, display-4)}/#{mat.line-height(
      var.$config,
      display-4
    )} mat.font-family(var.$config, display-4)};

  // Transitions
  --trans-ease-in-out: all var(--trans-ease-in-out-duration) var(--trans-ease-in-out-timing-function);
  --trans-ease-in-out-duration: #{mat_var.$swift-ease-in-out-duration};
  --trans-ease-in-out-timing-function: #{mat_var.$swift-ease-in-out-timing-function};
  --trans-ease-out: all var(--trans-ease-out-duration) var(--trans-ease-out-timing-function);
  --trans-ease-out-duration: #{mat_var.$swift-ease-out-duration};
  --trans-ease-out-timing-function: #{mat_var.$swift-ease-out-timing-function};
  --trans-ease-in: all var(--trans-ease-in-duration) var(--trans-ease-in-timing-function);
  --trans-ease-in-duration: #{mat_var.$swift-ease-in-duration};
  --trans-ease-in-timing-function: #{mat_var.$swift-ease-in-timing-function};
  --trans-shadow-duration: #{280ms};
  --trans-shadow-timing-function: #{cubic-bezier(0.4, 0, 0.2, 1)};
  --trans-shadow: box-shadow var(--trans-shadow-duration) var(--trans-shadow-timing-function);

  --text-color: mat.define-palette(mat.$dark-primary-text);
  --text-color-light: mat.define-palette(mat.$light-primary-text);
  --text-secondary: mat.define-palette(mat.$dark-secondary-text);
  --text-secondary-light: mat.define-palette(mat.$light-secondary-text);
  --text-hint: mat.define-palette(mat.$dark-disabled-text);
  --text-hint-light: mat.define-palette(mat.$light-disabled-text);

  --label-color: mat.define-palette(mat.$white-6-opacity);

  // Foreground
  --foreground-divider: rgb(var(--color-colorado));

  // Background
  --background-base: #fcfcfc;
  --background-card: #{map-get(map-get(var.$vex-theme, background), card)};
  --background-app-bar: #{map-get(map-get(var.$vex-theme, background), app-bar)};
  --background-hover: #{map-get(map-get(var.$vex-theme, background), hover)};

  // Sidenav
  --sidenav-width: 280px;
  --sidenav-collapsed-width: 72px;
  --sidenav-background: #{var.$sidenav-background};
  --sidenav-color: #1c1c1e;

  // Sidenav Item
  --sidenav-item-padding: var(--padding);
  --sidenav-toolbar-background: #{darken(var.$sidenav-background, 1.5)};
  --sidenav-item-background-active: #eaeaef;
  --sidenav-item-color: #1c1c1e;
  --sidenav-item-color-active: rgb(var(--color-primary));
  --sidenav-item-icon-color: #1c1c1e;
  --sidenav-item-icon-color-active: rgb(var(--color-primary));
  --sidenav-item-icon-gap: 10px;
  --sidenav-item-icon-size: 17px;
  --sidenav-item-border-color: transparent;
  --sidenav-item-border-color-active: rgb(var(--color-primary));
  --sidenav-item-dropdown-background: #eaeaef;
  --sidenav-item-dropdown-background-hover: #{darken(var.$sidenav-background, 4)};
  --sidenav-item-dropdown-gap: 12px;

  // Toolbar
  --toolbar-height: 60px;
  --toolbar-background: white;
  --toolbar-color: #{var.$dark-primary-text};
  --toolbar-icon-color: rgb(var(--color-primary));

  // Secondary Toolbar
  --secondary-toolbar-background: var(--background-card);
  --secondary-toolbar-height: 54px;

  // Navigation
  --navigation-height: 64px;
  --navigation-background: var(--background-card);
  --navigation-color: var(--text-secondary);

  // Footer
  --footer-height: 64px;
  --footer-z-index: 100;
  --footer-background: var(--background-card);
  --footer-color: var(--text-color);
  --footer-elevation: 0 -10px 30px 0 rgba(82, 63, 104, 0.06);

  // Page Layouts
  --page-layout-header-height: 200px;
  --page-layout-toolbar-height: 64px;

  // Misc
  --blink-scrollbar-width: 12px;
  --default-icon-size: 24px;
  --border-radius: 4px;

  // ng-select
  --selct-label-color: #{map-get(map-get(var.$vex-theme, foreground), secondary-text)};
  --select-container-bg: #{mat.get-color-from-palette(map-get(var.$vex-theme, foreground), base, 0.05)};
  --select-underline: #{mat.get-color-from-palette(map-get(var.$vex-theme, foreground), divider, 0.042)};
  --select-shadow: 0, 0, 0;
}
// @formatter:on
