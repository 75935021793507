@use '../../../../node_modules/@angular/material/index' as mat;
@use "../../var" as var;
.hohoro-style-dark {
  @include mat.all-component-themes(var.$vex-dark-theme);

  // Foreground
  --background-app-bar: #{map-get(map-get(var.$vex-dark-theme, background), app-bar)};

  // Background
  --footer-background: var(--background-card);
  --navigation-background: #{map-get(map-get(var.$vex-dark-theme, background), card)};
  --toolbar-background: #{map-get(map-get(var.$vex-dark-theme, background), card)};
  --background-base: #222222;
  --base-bg: 0, 0, 0;
  --base-fg: 255, 255, 255;

  // Colors
  --background-card: #{map-get(map-get(var.$vex-dark-theme, background), card)};
  --footer-color: var(--text-color);
  --navigation-color: var(--text-color);
  --text-color: #{var.$light-primary-text};
  --toolbar-color: #{var.$light-primary-text};
  --text-color-light: #{var.$dark-primary-text};

  // Toolbar
  --foreground-divider: rgb(var(--color-colorado));
  --text-hint: #{var.$light-disabled-text};

  // Navigation
  --text-hint-light: #{var.$dark-disabled-text};
  --background-hover: #{map-get(map-get(var.$vex-dark-theme, background), hover)};

  // Secondary Toolbar
  --text-secondary: #{var.$light-secondary-text};

  // Footer
  --text-secondary-light: #{var.$dark-secondary-text};
  --secondary-toolbar-background: var(--background-card);

  // ng-select
  --selct-label-color: #{map-get(map-get(var.$vex-dark-theme, foreground), secondary-text)};
  --select-container-bg: #{mat.get-color-from-palette(map-get(var.$vex-dark-theme, foreground), base, 0.15)};
  --select-underline: #{mat.get-color-from-palette(map-get(var.$vex-dark-theme, foreground), divider, 0.9)};
  --select-shadow: 0, 0, 0;
}
