// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming

// material customization
@use "src/styles/material/button";
@use "src/styles/material/table";
@use "src/styles/material/tabs";
@use "src/styles/material/dialog";
@use "src/styles/material/paginator";
@use "src/styles/material/menu-panel";
@use "src/styles/material/datepicker";
@use "src/styles/material/form-field";
@use "src/styles/material/snack-bar";
@use "src/styles/material/radio";

@use '../../node_modules/@angular/material/index' as mat;

// ui
@use "./ui/summary-card";
@use "./ui/icon";
@use "./ui/card";
// libs
@use "src/styles/libs/ng-select/material.theme";
@use "src/styles/libs/ng-select/ng-select.component";

// cdk
@use "src/styles/cdk/overlay";

@import '../../node_modules/@angular/material/theming';



// SCSS Variables
@import 'src/styles/var';

// Styles / CSS Variables
@import 'src/styles/partials/styles/style-dark';
@import 'src/styles/partials/styles/style-light';
@import 'src/styles/partials/styles/style-default';

// Mixins
@import 'src/styles/partials/mixins';

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core($config);

// Include theme styles for core and each modal-shared-components used in your app.
// Alternatively, you can import and @include the theme mixins for each modal-shared-components
// that you are using.
@include mat.all-component-themes($vex-theme);

// Partials
@import 'src/styles/partials/horizontal';
@import 'src/styles/partials/vertical';
@import 'src/styles/partials/print';
@import 'src/styles/partials/typography';
@import 'src/styles/partials/overrides';
@import 'src/styles/partials/scrollbar';
@import 'src/styles/partials/plugins/angular-material';
@import 'src/styles/partials/plugins/apexcharts';
// @import 'src/styles/partials/plugins/ng-slect';
@import 'src/styles/partials/plugins/snackbar';

html {
  box-sizing: border-box;
  font-size: 16px;
  height: 100%;
  overflow: hidden;
}

body {
  height: 100%;
  @include mat.typography-level($config, body-1);
  @apply text-black leading-normal antialiased;
  font-feature-settings: 'kern';
}

div {
  box-sizing: border-box;
}
.link {
  @apply text-margaret;
  @apply text-body-2;
  cursor: pointer;
}
