.mat-form-field-appearance-outline .mat-form-field-infix {
  padding: 0.7rem 0 !important;
  border-top: 0.4rem solid transparent !important;
}
.mat-form-field-appearance-outline .mat-form-field-wrapper {
  margin: 0.15em 0 !important;
}

.mat-form-field-appearance-outline .mat-form-field-prefix,
.mat-form-field-appearance-outline .mat-form-field-suffix {
  top: 0.1em !important;
}
.mat-form-field-subscript-wrapper {
  font-size: 0.75rem !important;
}

.mat-error {
  font-size: 0.75rem !important;
}
.no-hint {
  .mat-form-field-wrapper {
    padding-bottom: 0 !important;
  }
}
.mat-form-field-appearance-outline .mat-form-field-outline-start,
.mat-form-field-appearance-outline .mat-form-field-outline-end {
  border-color: #a7a4a4;
}
.mat-form-field-appearance-outline .mat-form-field-outline-gap {
  border-color: #a7a4a4;
}
.mat-form-field-appearance-outline .mat-form-field-outline-start,
.mat-form-field-appearance-outline .mat-form-field-outline-end {
  border-color: #a7a4a4;
}

.mat-form-field-appearance-outline .mat-form-field-outline {
  color: #d1d1d6;
}

.mat-form-field-suffix {
  display: flex;
}

textarea[readonly] {
  cursor: not-allowed;
}
