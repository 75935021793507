@mixin custom-card {
  padding: 0.85rem;
  background-color: rgb(var(--color-white));
  border: 0.5px solid #F2F2F7;
  box-shadow: 0px 4px 12px rgba(199, 199, 204, 0.3);
  border-radius: 0.85rem;
}

%custom-card {
  @include custom-card;
}

.custom-card {
  @extend %custom-card;
}

.summary-card {
  padding: 0.5rem;
  background-color: rgb(var(--color-gren));
  border-radius: 10px;
}

.auth-card {
  width: 100%;
  padding: 1rem;
  @screen sm {
    @include custom-card;
    max-height: 85%;
    max-width: 27rem;
    overflow-y: auto;
    padding: 2.25rem;
  }
}