@font-face {
  font-family: 'Noto Sans';
  src: local('Noto Sans Light'), local('NotoSansDisplay-Light'),
    url('./assets/fonts/noto/NotoSans-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'Noto Sans';
  src: local('Noto Sans Regular'), local('NotoSansDisplay-Regular'),
    url('./assets/fonts/noto/NotoSans-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Noto Sans';
  src: local('Noto Sans SemiBold'), local('NotoSansDisplay-SemiBold'),
    url('./assets/fonts/noto/NotoSans-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Noto Sans';
  src: local('Noto Sans ExtraBold'), local('NotoSansDisplay-ExtraBold'),
    url('./assets/fonts/noto/NotoSans-SemiBold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Noto Sans';
  src: local('Noto Sans Medium'), local('NotoSansDisplay-Medium'),
    url('./assets/fonts/noto/NotoSans-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Noto Sans';
  src: local('Noto Sans Bold'), local('NotoSansDisplay-Bold'),
    url('./assets/fonts/noto/NotoSans-SemiBold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

