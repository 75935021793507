.mat-snack-bar-container {
  white-space: pre-wrap;
  &.error-snack {
    background: rgb(var(--color-warn));
    color: rgb(var(--color-accent-contrast));
    .mat-button-wrapper {
      color: rgb(var(--color-accent-contrast));
    }
  }
  &.success-snack {
    background: rgb(var(--color-primary));
    color: rgb(var(--color-accent-contrast));
    .mat-button-wrapper {
      color: rgb(var(--color-accent-contrast));
    }
  }
}
