@use '../../../node_modules/@angular/material/index' as mat;
// Typography

/*
  InterDynamicTracking takes the font size in points
  or pixels and returns the compensating tracking in EM.
  function InterDynamicTracking(fontSize) {
    const a = -0.0223
    const b = 0.185
    const c = -0.1745;
    // tracking = a + b * e ^ (c * fontSize)
    return a + b * Math.pow(Math.E, c * fontSize)
  }tailwind
*/

.display-4 {
  @include mat.typography-level($config, display-4);
}

.display-3 {
  @include mat.typography-level($config, display-3);
}

.display-2 {
  @include mat.typography-level($config, display-2);
}

// .display-1, h1 {
//   @include mat.typography-level($config, display-1);
// }

.headline,
h2 {
  @include mat.typography-level($config, headline);
}

.title,
h3 {
  @include mat.typography-level($config, title);
}

.subheading-2,
h4 {
  @include mat.typography-level($config, subheading-2);
}

.subheading-1,
h5 {
  @include mat.typography-level($config, subheading-1);
}

.body-2,
h6 {
  @include mat.typography-level($config, body-2);
}

.body-1,
p {
  @include mat.typography-level($config, body-1);
}

.caption {
  @include mat.typography-level($config, caption);
}

.text-xs {
  letter-spacing: 0;
}

.text-sm {
  letter-spacing: -0.009em;
}

.text-base {
  letter-spacing: -0.011em;
}

.text-lg {
  letter-spacing: -0.014em;
}

.text-2xl {
  letter-spacing: -0.019em;
}

.text-3xl {
  letter-spacing: -0.021em;
}

:root h1 {
  font-family: 'Noto Sans', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 1.5rem;
  line-height: 2rem;
}

:root h2 {
  font-family: Noto Sans, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.35rem;
}

.text-body-1 {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
}

.text-body-2 {
  font-style: normal;
  font-weight: normal;
  font-size: 0.875rem;
  line-height: 1.187rem;
}

.text-body-3 {
  font-style: normal;
  font-weight: 500;
  font-size: 0.75rem;
  line-height: 1rem;
}

.text-body-4 {
  font-style: normal;
  font-size: 0.75rem;
  line-height: 1rem;
  font-weight: 400;
}

.text-body-5 {
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 300;
  line-height: 1rem;
}

.text-subtitle-1 {
  font-style: normal;
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 1.7rem;
}

.text-subtitle-2 {
  font-style: normal;
  font-weight: 500;
  font-size: 1.125rem;
  line-height: 1.562rem;
}

.text-subtitle-3 {
  font-style: normal;
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.187rem;
}

.text-caption-1 {
  letter-spacing: -0.015rem;
  font-style: normal;
  font-weight: bold;
  font-size: 0.625rem;
  line-height: 0.875rem;
}
