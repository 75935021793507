.mat-stroked-button,
.mat-flat-button {
  background-color: rgb(var(--color-shpongle)) !important;
  border-radius: 0.375rem !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 0.875rem !important;
  line-height: 1.1875rem !important;
  padding: 0.5rem 2.5rem !important;
  min-width: auto !important;
}
.mat-button {
  background-color: rgb(var(--color-shpongle)) !important;
  border-radius: 1rem !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 0.875rem !important;
  line-height: 1.1875rem !important;
  padding: 0.75rem 2.5rem !important;
}

.mat-icon-button {
  width: 2rem !important;
  height: 2rem !important;
  padding: 0 !important;
  border-radius: 50% !important;
}

.mat-button-base {
  background-color: rgb(var(--color-arizona)) !important;
  @apply text-body-1;
  padding: 0.5rem 1rem;
  color: rgb(var(--color-primary)) !important;
  line-height: 1.5rem !important;
  &.mat-primary {
    color: rgb(var(--color-white)) !important;
    background-color: rgb(var(--color-primary)) !important;
  }
  &.mat-button-disabled {
    background-color: rgb(var(--color-seattle)) !important;
    color: rgb(var(--color-texas)) !important;
  }
  &.mat-icon-button {
    background-color: transparent !important;
    color: rgb(var(--color-denver)) !important;
  }
  &.mat-margaret {
    background-color: rgb(var(--color-margaret)) !important;
    color: rgb(var(--color-white)) !important;
  }
}

.mat-dialog-actions .mat-button-base + .mat-button-base {
  margin-left: 0 !important;
}

.mat-button,
.mat-icon-button {
  &.cdk-focused,
  &.cdk-program-focused {
    box-shadow: none !important;
    // background: inherit !important;

    .mat-button-focus-overlay {
      display: none !important;
    }
  }
}

.mat-button:hover:not(.mat-button-disabled) .mat-button-focus-overlay,
.mat-stroked-button:hover:not(.mat-button-disabled) .mat-button-focus-overlay {
  opacity: 0 !important;
}
