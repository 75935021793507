.mat-dialog-container {
  box-shadow: none !important;
  border: 0.5px solid #f2f2f7 !important;
  box-sizing: border-box !important;
  border-radius: 1rem !important;
  padding: 1rem !important;
  overflow: hidden !important;
}
.without-container {
  .mat-dialog-container {
    padding: 0 !important;
    border: none !important;
  }
}
.mat-dialog-title {
  margin-bottom: 1rem;
}
.mat-dialog-content {
  margin: 0 -1rem !important;
  padding: 0 0.5rem 0 1rem !important;
}

.mat-dialog-actions {
  min-height: auto !important;
  margin-bottom: -0.5rem !important;
  padding-top: 0 !important;
}

.overflow-visible {
  .mat-dialog-container {
    overflow: visible !important;
  }
}
