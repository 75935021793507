@import 'mixins';

$ng-select-highlight: rgb(var(--color-margaret)) !default;
$ng-select-primary-text: rgb(var(--color-california)) !default;
$ng-select-primary-light-text: rgba(white, 0.87) !default;
$ng-select-secondary-text: rgba(black, 0.54) !default;
$ng-select-secondary-light-text: rgba(white, 0.54) !default;
$ng-select-disabled-text: rgba(black, 0.38) !default;
$ng-select-divider: rgb(var(--color-colorado)) !default;
$ng-select-bg: var(--background-base) !default;

.ng-select {
  width: 100%;
  // max-width: 10rem;
  &.has-error {
    .ng-select-container {
      &:after {
        border-color: rgb(var(--color-barbara));
        border-width: 1px;
      }
      &.ng-appearance-outline {
        &:after,
        &:hover:after {
          border-color: rgb(var(--color-barbara));
        }
      }
      .ng-value-container .ng-placeholder {
        color: rgb(var(--color-barbara));
      }
      .ng-arrow-wrapper .ng-arrow {
        color: rgb(var(--color-barbara));
      }
    }

    .ng-select-container {
      color: rgb(var(--color-barbara));

      &:hover {
        &.ng-appearance-outline:after {
          border-color: rgb(var(--color-barbara));
        }
      }

      .ng-value-container {
        .ng-placeholder {
          color: rgb(var(--color-barbara));
        }
      }
      &.ng-appearance-outline:after {
        border-width: 2px;
        border-color: rgb(var(--color-barbara));
      }
    }
  }
  &.ng-select-disabled {
    .ng-select-container {
      &:after {
        border-bottom-color: transparent;
        background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
        background-size: 4px 1px;
        background-repeat: repeat-x;
      }
      .ng-value-container {
        .ng-value {
          color: $ng-select-disabled-text;
        }
        .ng-placeholder {
          color: $ng-select-disabled-text;
        }
      }
    }
    .ng-arrow-wrapper .ng-arrow,
    .ng-clear-wrapper {
      color: $ng-select-disabled-text;
    }
  }
  &.ng-select-focused {
    .ng-select-container {
      &:after {
        border-color: $ng-select-highlight;
        border-width: 1px;
      }
      &.ng-appearance-outline {
        &:after,
        &:hover:after {
          border-color: $ng-select-highlight;
          border-width: 2px;
        }
      }

      .ng-arrow-wrapper .ng-arrow {
        color: $ng-select-highlight;
      }
    }
  }
  .ng-has-value,
  &.ng-select-filtered .ng-select-container {
    .ng-placeholder {
      display: initial;
    }
  }
  .ng-has-value,
  &.ng-select-opened {
    .ng-placeholder {
      transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.001px);
    }
  }
  .ng-select-container {
    color: $ng-select-primary-text;
    align-items: baseline;
    height: 3.187rem;

    padding: 0 10px;
    background-color: rgba(0, 0, 0, 0.04);

    &:after {
      border-bottom: thin solid rgba(0, 0, 0, 0.42);
      content: '';
      bottom: 0;
      left: 0;
      right: 0;
      position: absolute;
      transition: border-color 0.3s cubic-bezier(0.55, 0, 0.55, 0.2);
    }
    &.ng-appearance-outline {
      padding: 0 1rem 0 0.5rem;
      height: 2.95rem;
      margin-top: -0.3rem;
      background-color: transparent;
      &:after {
        border: solid 1px $ng-select-divider;
        border-radius: 5px;
        height: calc(100% - 0.5em);
        pointer-events: none;
        transition: border-color 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
      }
      &:hover:after {
        border-color: $ng-select-primary-text;
        border-width: 2px;
      }
      .ng-value-container {
        border-top: 0.54375em solid transparent;
      }
      .ng-placeholder {
        padding: 0 0.25em;
        background-color: $ng-select-bg;
        z-index: 1;
      }
      .ng-value {
        padding-left: 0.25em;
      }
      &.ng-select-opened {
        .ng-placeholder {
          transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.001px);
        }
      }
    }
    .ng-value-container {
      align-items: stretch;
      padding: 0.5em 0;
      border-top: 0.84375em solid transparent;
      .ng-placeholder {
        position: absolute;
        color: $ng-select-secondary-text;
        transform-origin: left 0;
        transition: transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1), color 0.4s cubic-bezier(0.25, 0.8, 0.25, 1),
          width 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
        @include rtl {
          transform-origin: right 0;
        }
      }
      .ng-input {
        bottom: 0.4375em;
      }
    }
  }
  &.ng-select-single {
    .ng-select-container {
      &.ng-appearance-outline {
        &.ng-has-value {
          color: rgb(var(--color-betty));
          .ng-value-label {
            width: 100%;
            display: block;
            padding-right: 1rem;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
        .ng-arrow-wrapper {
          // bottom: 5px;
        }
        .ng-clear-wrapper {
          bottom: 0.375rem;
        }
      }
      .ng-arrow-wrapper {
        align-self: flex-end;
        bottom: 13px;
      }
      .ng-clear-wrapper {
        align-self: flex-end;
        bottom: 7px;
      }
    }
  }
  &.ng-select-multiple {
    &.ng-select-disabled {
      .ng-select-container .ng-value-container .ng-value {
        background-color: $ng-select-divider;
        color: rgba(0, 0, 0, 0.26);
      }
      .ng-appearance-outline {
        &:after,
        &:hover:after {
          background-image: none;
          border: dotted 1px $ng-select-divider;
        }
      }
    }
    .ng-select-container {
      &.ng-appearance-outline {
        &.ng-has-value {
          .ng-arrow-wrapper,
          .ng-clear-wrapper {
            border-top: none;
          }
        }
        .ng-arrow-wrapper {
          top: 0;
        }
        .ng-clear-wrapper {
          top: 4px;
        }
      }
      .ng-value-container {
        .ng-value {
          background-color: $ng-select-highlight;
          border-radius: 2px;
          color: $ng-select-bg;
          padding: 0 5px;
          margin: 0 0.4375em 0.175em 0;

          &.ng-value-disabled {
            background-color: $ng-select-divider;
            color: rgba(0, 0, 0, 0.26);
          }
          .ng-value-label {
            max-width: 80px;
            display: block;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            @apply text-body-4;
          }
          .ng-value-icon {
            color: $ng-select-secondary-light-text;
            padding-right: 5px;
            @include rtl {
              padding-left: 5px;
              padding-right: 0;
            }
            &:hover {
              color: $ng-select-primary-light-text;
            }
          }
        }
        .ng-input {
          line-height: 1.375em;
        }
      }
      &.ng-has-value {
        align-items: center;
        .ng-value-container {
          padding-bottom: 0;
          padding-top: 0.1875em;
          &.ng-appearance-outline {
            .ng-clear-wrapper,
            .ng-arrow-wrapper {
              border-top: 0.84375em solid transparent;
            }
          }
        }
      }
    }
  }
  .ng-clear-wrapper {
    color: $ng-select-secondary-text;
    &:hover {
      color: $ng-select-primary-text;
    }
  }
  .ng-arrow-wrapper {
    bottom: 2px;
    width: 1rem !important;
    height: 1rem !important;
    .ng-arrow {
      width: 0.5rem !important;
      height: 0.5rem !important;
      background: rgb(var(--color-denver));
      mask-repeat: no-repeat;
      mask-position: center center;
      mask-size: contain;
      mask-image: url('../../../assets/img/custom-icons/angle-down.svg');
      color: $ng-select-secondary-text;
    }
  }
  .ng-spinner-zone {
    // padding-top: 46px;
  }
}

.ng-dropdown-panel {
  background: $ng-select-bg;
  left: 0;
  box-shadow: 0px 2px 4px rgba(188, 188, 189, 0.4);
  &.ng-select-bottom {
    top: calc(100% + 0.5em);
  }
  &.ng-select-top {
    bottom: calc(100% - 1px);
  }
  &.multiple {
    .ng-option {
      &.selected {
        background: $ng-select-bg;
      }
      &.marked {
        background: rgba(0, 0, 0, 0.04);
      }
    }
  }
  .ng-dropdown-header {
    padding: 0 16px;
    line-height: 3em;
    min-height: 3em;
  }
  .ng-dropdown-footer {
    border-top: 1px solid $ng-select-divider;
    padding: 0 16px;
    line-height: 3em;
    min-height: 3em;
  }
  .ng-dropdown-panel-items {
    .ng-optgroup {
      user-select: none;
      cursor: pointer;
      line-height: 3em;
      height: 3em;
      padding: 0 16px;
      color: $ng-select-secondary-text;
      font-weight: 500;
      &.ng-option-marked {
        background: rgba(0, 0, 0, 0.04);
      }
      &.ng-option-disabled {
        cursor: default;
      }
      &.ng-option-selected {
        background: $ng-select-divider;
        color: $ng-select-highlight;
      }
    }
    .ng-option {
      line-height: 3em;
      min-height: 3em;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      padding: 0 16px;
      text-decoration: none;
      position: relative;
      color: $ng-select-primary-text;
      text-align: left;
      @include rtl {
        text-align: right;
      }
      &.ng-option-marked {
        background: rgba(0, 0, 0, 0.04);
        color: $ng-select-primary-text;
      }
      &.ng-option-selected {
        background: rgb(var(--color-arizona));
        color: rgb(var(--color-betty));
      }
      &.ng-option-disabled {
        color: $ng-select-disabled-text;
      }
      &.ng-option-child {
        padding-left: 32px;
        @include rtl {
          padding-right: 32px;
          padding-left: 0;
        }
      }
      .ng-tag-label {
        padding-right: 5px;
        @include rtl {
          padding-left: 5px;
          padding-right: 0;
        }
        font-size: 80%;
        font-weight: 400;
        color: $ng-select-disabled-text;
      }
    }
  }
}
