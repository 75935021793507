input,
textarea {
  box-sizing: content-box;
  line-height: 1.5;
  max-height: 10rem;
}

button:focus {
  outline: unset !important;
}

code {
  background: var(--background-app-bar);
  border-radius: var(--border-radius);
  color: var(--text-color);
  font-family: SFMono-Regular, Consolas, Liberation Mono, Menlo, monospace;
  font-size: 85%;
  padding: 0.2em 0.4em;
}

blockquote {
  background: theme('backgroundColor.primary.light');
  border-left: 3px solid theme('backgroundColor.primary.DEFAULT');
  color: rgba(0, 0, 0, 0.87);
  font-style: normal;
  margin: 1em 0 1.5em;
  padding: 1em 1.5em;

  > * {
    margin: 0;
  }
}

html {
  @media (min-width: 768px) {
    ::-webkit-scrollbar {
      background-color: transparent;
      width: 16px;
      margin-top: 2px;
      margin-bottom: 2px;
    }

    /* background of the scrollbar except button or resizer */
    ::-webkit-scrollbar-track {
      background-color: transparent;
    }

    /* scrollbar itself */
    ::-webkit-scrollbar-thumb {
      background-color: #babac0;
      border-radius: 16px;
      border: 4px solid #fff;
      opacity: 0.5;
    }

    /* set button(top and bottom of the scrollbar) */
    ::-webkit-scrollbar-button {
      display: none;
    }

    ::-webkit-scrollbar-thumb {
      background-color: rgba(0, 0, 0, 0);
      border-radius: 16px;
      border: 5px solid #fff;
    }

    :hover::-webkit-scrollbar-thumb {
     // background-color: #a0a0a5;
    }

    ::-webkit-scrollbar-thumb:hover {
     // background-color: #a0a0a5;
      // border:4px solid #f4f4f4
    }
  }
}

/* Change autocomplete styles in WebKit */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: none;
  -webkit-text-fill-color: #000;
  -webkit-box-shadow: 0 0 0 1000px #fff inset;
  transition: background-color 5000s ease-in-out 0s;
}

.disabled {
  pointer-events: none;
  opacity: 0.5;
}
