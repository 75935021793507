.mat-tab-label {
  padding: 0 0 0.5rem 0 !important;
  min-width: auto !important;
  margin-right: 1.25rem !important;
  opacity: 1 !important;
  height: auto !important;
  @apply text-body-1;
}
.mat-tab-label,
.mat-tab-link {
  color: rgb(var(--color-texas)) !important;
}
.mat-tab-label-active {
  color: rgb(var(--color-betty)) !important;
}

.mat-ink-bar {
  width: 1.5rem !important;
  height: 0.187rem !important;
  border-radius: 0.25rem !important;
}

.mat-tab-nav-bar,
.mat-tab-header {
  border-bottom: none !important;
}

.mat-tab-body {
  animation: fade-out 0.5s;
  opacity: 0;
  overflow: hidden !important;
}
.mat-tab-body.mat-tab-body-active {
  animation: fade-in 0.5s;
  opacity: 1;
  overflow: hidden !important;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fade-out {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

.tab-label-container-gutter {
  .mat-tab-label-container {
    margin: 0 1.875rem;
  }
}
