.mat-icon-button .mat-button-wrapper > *,
.mat-menu-item .mat-icon,
.mat-button .mat-icon {
  vertical-align: middle !important;
}

.mat-form-field-suffix,
.mat-form-field-prefix {
  .mat-icon-button .mat-icon {
    font-size: inherit;
  }
}

.mat-table {
  width: 100%;
  mat-row,
  mat-header-row,
  mat-footer-row,
  th.mat-header-cell,
  td.mat-cell,
  td.mat-footer-cell {
    border-bottom-color: rgb(var(--color-seattle));
  }
}

.mat-form-field {
  margin-bottom: 4px;
}

.mat-primary.mat-form-field {
  .mat-form-field-prefix,
  .mat-form-field-suffix {
    transition: var(--trans-ease-out);
  }

  &.mat-focused .mat-form-field-prefix,
  &.mat-focused .mat-form-field-suffix {
    @apply text-primary;
  }
}

.mat-form-field.mat-form-field-invalid {
  &.mat-focused .mat-form-field-prefix,
  &.mat-focused .mat-form-field-suffix {
    @apply text-red;
  }
}

.mat-table .mat-cell,
.mat-table .mat-header-cell {
  box-sizing: content-box;
  padding-left: var(--padding-12);
  padding-right: var(--padding-12);
  // white-space: nowrap;
}

.mat-paginator-page-size-select.mat-form-field .mat-form-field-flex {
  padding-top: 0;
}

.mat-menu-item ic-icon {
  margin-inline-end: var(--padding-16);
  vertical-align: middle;

  > svg {
    vertical-align: middle;
  }
}

.mat-select-panel {
  @apply text-base;
}

.mat-drawer-side {
  border: 0;
}

textarea.mat-input-element {
  @apply leading-normal;
}

.vex-flex-form-field {
  .mat-form-field-infix {
    width: 50px;
  }
}

.vex-dense-form-field {
  margin-bottom: -1.34375em;

  &.mat-form-field-appearance-outline {
    .mat-form-field-infix {
      padding-top: 4px;
    }
  }
}

.vex-tabs {
  .mat-tab-label.mat-tab-label-active {
    opacity: 1;
  }

  .mat-tab-link.mat-tab-label-active {
    opacity: 1;
  }
}

.vex-tabs-dense {
  .mat-tab-label {
    min-width: 0;
  }
}

.uppercase > * > .mat-sort-header-button {
  text-transform: uppercase;
}

.mat-card-image {
  max-width: none;
}

/*
.mat-button-focus-overlay {
  background: rgba(var(--palette-foreground-base), 1);
}
.mat-checkbox .mat-ripple-element {
  background-color: rgb(var(--palette-foreground-base));
}
.mat-option.mat-option-disabled {
  color: rgba(var(--palette-foreground-hint-text), 0.38);
}
*/
// responsive styles for material table
table {
  width: 100%;
}

td.mat-column-star {
  width: 20px;
  padding-right: 8px !important;
}

.mat-row {
  &:hover {
    background-color: #e9f3ff;
  }
  a {
    color: #3e75ff;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
}

@media screen and (max-width: 960px) {
  .mat-elevation-z8 {
    background: transparent;
    box-shadow: none;
  }

  .mat-header-row {
    display: none;
  }

  tbody {
    :not(.mat-calendar-body) & :not(tr) & :not(td) {
      display: block;
      width: 100%;
    }
  }

  .mat-table {
    background: transparent;
    * {
      box-sizing: border-box;
    }

    .mat-row {
      display: block;
      overflow: hidden;
      height: auto;
      position: relative;
      clear: both;
      box-shadow: inset 0px -1px 0px #e5e5ea;
      background-color: transparent;
      + .mat-row {
        padding: 0;
      }
      &:first-child {
      }
    }

    .mat-cell {
      display: block;

      padding: 0 16px 0 0;
      margin: 16px 0;
      border: 0 none;
      &:first-child {
        padding: 0 48px 0 0;
        a {
          font-size: 20px;
          // font-weight: 400;
          color: inherit;
        }
        // &:before {
        //   display: none;
        // }
      }
      &.m-card-sub-title {
        margin-top: -8px;
        padding: 0 48px 0 16px;
        color: rgba(0, 0, 0, 0.54);
      }
    }

    .show-mobile-label {
      &:before {
        content: attr(data-label);
        display: inline;
        font-weight: normal;
        margin-right: 0.5em;
        font-weight: bold;
      }
    }

    // .mat-column-star {
    //   width: auto;
    //   padding: 8px 0 0 !important;
    //   margin: 0;
    //   position: absolute;
    //   top: 0;
    //   right: 0;
    //   &:before {
    //     display: none;
    //   }
    // }
  }

  .mat-paginator {
    margin-top: 24px;
  }
}
.hoho-tabs {
  .mat-tab-labels {
    width: 100%;
    flex-grow: 1;
    background: #f2f2f7;
    padding: 4px;
    border-radius: 16px;
  }

  .mat-tab-label {
    flex-grow: 1;
    text-align: center;
    width: 100%;
    display: flex;
    padding: 7px !important;
    margin: 0 !important;
    font-size: 12px;
    color: #6b6b73 !important;
    &.mat-tab-label-active {
      background: #5856d6 !important;
      color: #ffffff !important;
      border-radius: 14px;
    }
  }

  mat-ink-bar.mat-ink-bar {
    display: none;
  }
}
tr.mat-header-row {
  height: 40px !important;
}

.mat-bottom-sheet-container {
  box-shadow: none !important;
}

.mat-bottom-sheet-container-medium {
  width: 20rem !important;
  min-width: auto !important;
}
